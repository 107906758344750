<template>
  <q-dialog v-model="isOpen">
    <q-card style="min-width: 90vw;">
      <q-card-section class="row">
        <div class="text-h6 text-center">
          {{ $t('Import Items') }}
        </div>

        <q-space/>

        <q-btn
            color="transparent"
            text-color="dark"
            size="sm"
            icon="close"
            no-caps
            unelevated
            @click="close"
        />
      </q-card-section>

      <q-card-section class="text-center">

        <q-card>
          <q-card-section class="row border-bottom items-center full-width q-py-xs q-pl-none">
            <q-legend
                :label="$t('Items')"
                text-class="text-h6"
            />

            <search
                dense
                autoset
                is-expandable
                @submit="handleSearch"
            />

            <q-space/>

            <q-btn
                :color="serverParams.filter && serverParams.filter.length > 0 ? 'light-blue-9' : 'dark'"
                text-color="white"
                size="sm"
                class="q-mr-sm"
                :label="filterBtnText"
                no-caps
                unelevated
                @click="openCloseFilters"
            />

            <q-btn
                color="dark"
                text-color="white"
                :label="$t('Refresh')"
                size="sm"
                class="q-mr-sm"
                no-caps
                unelevated
                @click="refreshItems"
            />
          </q-card-section>

          <q-card-section class="q-ma-none q-pa-none">
            <filters-collapse
                :is-open="isOpenFilters"
                :options="{
              defaultFilter: serverParams.filter,
              fields: activatedFields,
              values: {
                'states==i': states,
              }
            }"
                @submit="handleFiltersSubmit"
                @close="openCloseFilters"
                @update="handleFilterUpdate"
            />
          </q-card-section>

          <q-card-section
              v-if="isNeededWarehouse"
              class="row bg-teal border-bottom q-pa-sm items-center justify-center text-center text-subtitle1"
              style="min-height: 80vh;"
          >
            {{ $t('You need to create warehouse. You can make this from') }}

            <router-link
                to="/warehouse"
                class="text-success q-ml-sm"
            >
              {{ $t('here.') }}
            </router-link>
          </q-card-section>

          <q-card-section
              v-else
              class="q-pa-none"
          >
            <q-table
                style="height: calc(100vh - 130px);"
                class="sticky-header-table"
                row-key="id"
                :rows-per-page-label="$t('Rows per page')"
                :rows="storageItems"
                :columns="columns"
                v-model:pagination="pagination"
                :loading="storageItemsLoading"
                :filter="filter"
                :rows-per-page-options="[25, 50, 100, 150, 200, 250]"
                virtual-scroll
                binary-state-sort
                flat
                @request="onRequest"
            >

              <template v-slot:loading>
                <q-inner-loading
                    showing
                    color="primary"
                />
              </template>

              <template v-slot:body="props">
                <order-items-row
                    :items="selectedItems"
                    :data="props"
                    @check="handleCheck"
                />
              </template>

            </q-table>
          </q-card-section>
        </q-card>

      </q-card-section>

      <q-card-section class="text-center">
        <q-btn
            color="light-blue-9"
            text-color="white"
            class="q-mr-sm"
            :label="$t('Save')"
            @click="save"
        />

        <q-btn
            color="dark"
            text-color="white"
            :label="$t('Close')"
            @click="close"
        />
      </q-card-section>

      <q-card-section class="text-center">
        <q-card-section class="q-pt-lg q-mt-sm" v-if="isLoading">
          <q-linear-progress stripe size="10px" class="" ref="progress" :value="progressValue"/>
        </q-card-section>
      </q-card-section>

      <q-card-section v-if="model.length > 0">
        <q-list bordered separator :class="{ 'bg-warning': item?.state === 'error' }">
          <q-item v-for="item in model" :key="item.sku" v-ripple class="row">

            <q-item-section avatar>
              <img
                  style="max-height: 50px; width: 60px; object-fit: contain"
                  :src="item.offer?.image || fallbackImage"
                  @error="onImageLoadFailure"
              />
            </q-item-section>

            <q-item-section class="col-2">
              <q-input
                  standout="bg-teal text-white"
                  type="text"
                  :model-value="item.sku"
                  :label="$t('SKU')"
                  :readonly="true"
              />
            </q-item-section>

            <q-item-section class="col-2">
              <q-input
                  standout="bg-teal text-white"
                  type="text"
                  :model-value="item.rootValue"
                  :label="$t('Root value')"
                  @update:model-value="updateItem(item.sku,'rootValue', $event)"
              />
            </q-item-section>

            <q-item-section>
              <span v-if="item.offer?.id">
                {{ item.offer.name }} ({{ item.offer.id }})<br/>
                <strong>{{ item.offer.sku }}</strong> / {{ item.offer.barcodes }}
              </span>
              <span v-if="!item.offer?.id">
                {{ item.offer?.name }}
              </span>
            </q-item-section>

            <q-input
                standout="bg-teal text-white"
                type="number"
                :model-value="item.quantity"
                :label="$t('Quantity')"
                @update:model-value="updateItem(item.sku,'quantity', $event)"
            />

            <q-item-section style="max-width: 80px" class="q-pl-sm">
              <q-btn
                  color="negative"
                  size="sm"
                  :label="$t('Delete')"
                  @click="handleDelete(item.sku )"
              />
            </q-item-section>
          </q-item>
        </q-list>
      </q-card-section>

      <q-card-section class="text-center" v-if="model.length > 0">
        <q-btn
            color="light-blue-9"
            text-color="white"
            class="q-mr-sm"
            :label="$t('Save')"
            @click="save"
        />

        <q-btn
            color="dark"
            text-color="white"
            :label="$t('Close')"
            @click="close"
        />
      </q-card-section>
    </q-card>
  </q-dialog>

</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'

// Mixins
import TableMixin from '../../components/global/TableMixin'

// Components
import { buildQuery } from '../../utils/query-utils'
import Search from '@/apps/app/components/search/Search.vue'
import OrderItemsRow from '@/apps/app/components/items/OrderItemsRow.vue'
import FiltersCollapse from '@/apps/app/components/filters/FilterCollapse.vue'

export default {
  name: 'ImportItemsModal',
  components: { FiltersCollapse, OrderItemsRow, Search },
  mixins: [
    TableMixin
  ],
  emits: ['submit'],
  data () {
    return {
      fallbackImage: 'assets/img/fallback-image/package.png',
      isOpen: false,
      model: [],
      warehouse: null,
      isLoading: false,
      progressValue: 0,
      rawModel: '',
      filter: '',
      isOpenFilters: false,
      isNeededWarehouse: false,
      pagination: {
        descending: true,
        page: 1,
        rowsPerPage: 25,
        rowsNumber: 25
      },
      states: [
        { id: 'normal', title: this.$t('Normal') },
        { id: 'delivered', title: this.$t('Delivered') },
        { id: 'blocked', title: this.$t('Blocked') },
        { id: 'booked', title: this.$t('Booked') },
        { id: 'deleted', title: this.$t('Deleted') },
        { id: 'new', title: this.$t('New') },
        { id: 'returned', title: this.$t('Returned') },
        { id: 'shipped', title: this.$t('Shipped') },
        { id: 'expected', title: this.$t('Expected') },
        { id: 'defected', title: this.$t('Defected') }
      ],
      columns: [
        {
          label: '#',
          name: 'number',
          align: 'left'
        },
        {
          label: this.$t('Id'),
          name: 'id',
          align: 'center'
        },
        {
          label: this.$t('Status'),
          name: 'state',
          align: 'left'
        },
        {
          label: this.$t('Type'),
          name: 'type',
          align: 'left'
        },
        {
          label: this.$t('Product offer'),
          name: 'productOffer',
          align: 'left'
        },
        {
          label: this.$t('Place'),
          name: 'place',
          align: 'center'
        },
        {
          label: this.$t('Sku'),
          name: 'sku',
          align: 'center'
        },
        {
          label: this.$t('Inbound item'),
          name: 'acceptanceItem',
          align: 'center'
        },
        {
          label: this.$t('Batch'),
          name: 'batch',
          align: 'center'
        }
      ],
      activatedFields: [
        'id',
        'sku',
        'warehouse',
        'weight.to',
        'weight.from',
        'state==i',
        'createdByDocumentId',
        'acceptanceItem',
        'productOffer==i'
      ],
      selectedItems: [],
    }
  },
  computed: {
    ...mapGetters([
      'totalStorageItemsNumber',
      'storageItemsLoading',
      'storageItems'
    ]),
    filterBtnText () {
      return this.serverParams.filter && this.serverParams.filter.length > 0
          ? this.$t('Filtered: ') + this.totalStorageItemsNumber
          : this.$t('Filter')
    },
  },
  methods: {
    close () {
      this.isOpen = false
    },
    open (warehouse) {
      this.warehouse = warehouse
      this.isOpen = true
      this.loadDefaultItems()
    },
    save () {
      this.isOpen = false

      this.$emit('submit', { items: this.selectedItems })
    },
    onImageLoadFailure (e) {
      e.target.src = 'assets/img/fallback-image/package.png'
    },
    ...mapActions([
      'loadStorageItems'
    ]),
    ...mapMutations([]),
    handleSearch (search) {
      return this.onRequest({ pagination: { page: 1, search } })
    },
    onRequest (data = {}) {
      this.pagination = data.pagination || {}
      const query = buildQuery(this.pagination)

      if (query.search && query.search[query.search.length - 1] !== '*' && query.search[query.search.length - 2] !== ':' && !query.search.includes('%')) {
        query.search += query.search[query.search.length - 1] === ':'
            ? '*'
            : ':*'
      }

      if (data.filter) {
        query.filter = data.filter
      }

      return this.loadStorageItems(query)
          .then(({ page, totalItems }) => {
            this.pagination = {
              ...this.pagination,
              page,
              rowsNumber: totalItems
            }

          })
          .catch(err => {
            if (err.message === 'No warehouses found') {
              this.isNeededWarehouse = true
            }
          })
    },
    openCloseFilters () {
      this.isOpenFilters = !this.isOpenFilters
    },
    refreshItems () {
      return this.onRequest({
        pagination: {
          forceReload: true
        }
      })
    },
    handleFiltersSubmit (filter) {
      this.isOpenFilters = false
      return this.onRequest({ pagination: { filter, page: 1 } })
    },
    handleFilterUpdate ({ value, oldValue }) {
      if (oldValue['state==i'].length <= 0 && value['state==i'].length > 0 && !value.items.from) {
        value.items.from = 1
      }
    },
    loadDefaultItems () {
      let filter = [
        {
          type: 'eq',
          field: 'warehouse',
          value: this.warehouse?.id
        },
        {
          type: 'eq',
          field: 'state',
          value: 'normal'
        },
      ]

      return this.onRequest({ filter, pagination: {} })
    },
    handleCheck (item) {
      let hasItem = false

      this.selectedItems = this.selectedItems.filter(x => {
        if (x.id === item.id) {
          hasItem = true
          return false
        }

        return true
      })

      if (!hasItem) {
        this.selectedItems = [item, ...this.selectedItems]
      }
    },
  }
}
</script>
